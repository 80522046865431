import { createSelector, createStructuredSelector } from 'reselect';

export const getUser = (state) => state;

export const getIsUserLoggedIn = (state) => state.userloggedin;

export const getUserEmail = createSelector(
    [getUser],
    (user) => user.currentuser.email
);

export const getAuthToken = createSelector(
    [getUser],
    (user) => user.currentuser.accessToken
);

