import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getString as stringValue } from "firebase/remote-config";
import { firebaseConfig }  from "./config";

const env = process.env.REACT_APP_DATA_ENV;

const config = firebaseConfig[env];
const app = initializeApp(config);
const auth = getAuth(app);
const configs = getRemoteConfig(app);

const getString = (key) => {
  return stringValue(configs, key)
}

export { app, auth, getString, configs };
