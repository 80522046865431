import React from 'react';
import '../PrivacyPolicy/PrivacyPolicy.css';

class TermsAndConditions extends React.Component {

  render() {
    return (
      <div className="privacy-policy">
        <h1>TheBookingPlatform SUPPLIER TERMS AND CONDITIONS</h1>

        <p><strong>THIS AGREEMENT</strong> is made between THE BOOKING PLATFORM LTD ("Company"), a company operating a platform for tours, activities, and services, and the undersigned independent third-party supplier ("Supplier").</p>

        <p><strong>WHEREAS</strong>, the Company desires to include the Supplier’s services on its platform; and <strong>WHEREAS</strong>, the Supplier wishes to provide services through the Company's platform; the parties hereby agree as follows:</p>

        <h2>ARTICLE I: ACCEPTANCE AND TERM</h2>
        <p><strong>1. Acceptance:</strong> This Agreement represents the complete understanding between the parties and supersedes all prior agreements. The Supplier’s provision of services under the Company's platform shall signify acceptance of these terms.</p>

        <h2>ARTICLE II: SUPPLIER OBLIGATIONS</h2>
        <p><strong>2.1 Operational Compliance:</strong> The Supplier is required to manage bookings and interactions with customers through the Company's designated interface, adhering to all Company policies and procedures. Unless otherwise agreed upon, all Products offered by the Supplier must be available for “Instant Confirmation Booking.” The Supplier must accept all such bookings without undue delay. For “On Request Booking,” the Supplier is obligated to accept or reject the booking within forty-eight (48) hours. The Supplier must notify the Company of any changes, cancellations, or significant updates to the Products or services at least six (6) months in advance, or as soon as practicable.</p>

        <p><strong>2.2 Cancellation Protocol:</strong> The Supplier agrees to adhere to the Company's cancellation and no-show policies, which shall not be more restrictive than the Supplier's direct booking policies. This ensures consistency and reliability for customers. The Supplier must use commercially reasonable efforts to accommodate customers who are late, within reasonable limits.</p>

        <p><strong>2.3 Technology Utilisation:</strong> The Supplier is required to utilise the technology provided by the Company in accordance with the terms of this Agreement. The Supplier is responsible for procuring, maintaining, and correctly configuring any necessary hardware, software, and services needed to facilitate bookings and interactions on the Company’s platform. Any third-party technology partners engaged by the Supplier must adhere to the Company's standards for confidentiality and data protection.</p>

        <p><strong>2.4 Customer Communication:</strong> All communication with customers must be conducted through the Company's designated interface, except in exceptional circumstances where direct contact is authorised by the Company.</p>

        <p><strong>2.5 Voucher Protocol:</strong> The Supplier agrees to accept electronic vouchers for each Product sold through the Company’s platform. In cases where electronic vouchers are not feasible, the Supplier must obtain prior approval from the Company, and alternative arrangements may be made at the Company’s discretion.</p>

        <p><strong>2.6 Content and Data Restrictions:</strong> The Supplier is prohibited from systematically analysing, scraping, or extracting information or data from the Company's platform or affiliated websites. Display of content from the Company's platform on the Supplier’s website is not permitted without prior authorization.</p>

        <h2>ARTICLE III: INSURANCE REQUIREMENTS</h2>
        <p><strong>3.1 Insurance Obligations:</strong> The Supplier must procure and maintain current Public (Product) liability insurance and errors and omissions insurance with coverage limits in line with industry standards and applicable laws. The insurance should be from an insurer with a suitable financial strength rating and include necessary coverage such as completed operations and personal injury liability. Upon the Company’s request, the Supplier must add the Company as an additional insured to such policies and provide proof of insurance coverage.</p>

        <p><strong>3.2 Adjustments to Insurance:</strong> The Company reserves the right to request specific additional coverage or increased coverage based on the Supplier’s Product offerings. The Supplier is obligated to maintain insurance at such levels upon the Company’s request. Failure to comply with these insurance requirements may result in deactivation or termination of this Agreement.</p>

        <h2>ARTICLE IV: TERM AND TERMINATION</h2>
        <p><strong>4.1 Term:</strong> This Agreement shall commence on the date of acceptance and shall continue until terminated by either party with appropriate notice.</p>

        <p><strong>4.2 Termination:</strong> Either party may terminate this Agreement for cause, including but not limited to breach of terms, insolvency, or failure to comply with legal or regulatory requirements. The terminating party must provide written notice of termination.</p>

        <h2>ARTICLE V: PAYMENT TERMS</h2>
        <p><strong>5.1 Payment Structure:</strong> The Supplier shall receive payment for services rendered according to the rates and prices agreed upon with the Company. The Company will collect payments from customers on behalf of the Supplier and remit them to the Supplier in accordance with the agreed payment schedule.</p>

        <p><strong>5.2 Payment Schedule:</strong> Payments to the Supplier will be made on a monthly basis, within thirty (30) days of the end of each calendar month. The Company will provide a detailed statement of all bookings and corresponding payments due to the Supplier.</p>

        <p><strong>5.3 Deductions and Adjustments:</strong> The Company reserves the right to deduct or withhold any amounts due to refunds, cancellations, chargebacks, or disputes. Any adjustments due to errors or discrepancies must be communicated by the Supplier within fourteen (14) days of receipt of the monthly statement.</p>

        <p><strong>5.4 Taxes and Fees:</strong> The Supplier is responsible for all applicable taxes, including sales tax, value-added tax (VAT), or any other taxes related to the services provided. The Supplier shall indemnify the Company for any tax liabilities arising from the Supplier’s failure to comply with tax obligations.</p>

        <h2>ARTICLE VI: MISCELLANEOUS</h2>
        <p><strong>6.1 Governing Law:</strong> This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is registered.</p>

        <p><strong>6.2 Amendment:</strong> This Agreement may only be amended in writing, signed by both parties.</p>

        <p><strong>6.3 Independent Contractor:</strong> The Supplier acknowledges that they are an independent contractor and not an employee, agent, joint venturer, or partner of the Company.</p>

        <p><strong>6.4 Confidentiality:</strong> Both parties agree to maintain the confidentiality of any proprietary information received during the term of this Agreement.</p>

        <p><strong>6.5 Limitation of Liability:</strong> The Company’s liability under this Agreement shall be limited to the maximum extent permitted by law.</p>

        <p><strong>6.6 Entire Agreement:</strong> This Agreement constitutes the entire agreement between the parties and supersedes all prior understandings or agreements on the subject matter.</p>

        <p><strong>END OF AGREEMENT</strong></p>
        <p>By clicking the “Accept” button, the Supplier acknowledges and agrees to all terms and conditions set forth in this Agreement.</p>
      </div>

    );
  }
}

export default TermsAndConditions;
