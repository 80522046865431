import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_DETAILED_AVALIABILITIES } from '../graphql/queries';
import { EDIT_AVALIABILITY, RESERVE_BOOKING, CANCEL_BOOKING } from '../graphql/mutations';
import React, { useState, useEffect } from 'react';
import { dayjsLocalizer } from 'react-big-calendar';
import { Calendar } from 'react-big-calendar';
import { Views } from 'react-big-calendar';
import { mapAvailabilitiesToEvents } from '../utils/CalendarDataMapper';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { LiaEdit, LiaPlusSquare, LiaChevronLeftSolid, LiaChevronRightSolid } from "react-icons/lia";
import { useFormik } from 'formik';
import Modal from 'react-modal';
import { authError } from '../utils/ErrorMapper';
import dayjs from 'dayjs';
import Preloader from '../components/Preloader';
import Layout from "../layout";
import { Spinner } from 'react-bootstrap';
import { ButtonGroup, FormGroup } from 'react-bootstrap';

const now = new Date();

const localizer = dayjsLocalizer(dayjs);

const EmptyGutter = () => <div />;

export const CalendarTodayButton = ({
	setDate,
	date,
	unitType,
}) => {
	return (
		<ButtonGroup style={{ alignItems: 'center' }}>
			<LiaChevronLeftSolid className='calendar-chevron' onClick={() => setDate(dayjs(date).add(-1, unitType).toDate())} />
			<button className='calendar-today-button' onClick={() => setDate(dayjs().toDate())}>
				{'Today'}
			</button>
			<LiaChevronRightSolid className='calendar-chevron' onClick={() => setDate(dayjs(date).add(1, unitType).toDate())} />
		</ButtonGroup>
	);
};

const Highlightevent = ({ event, onEdit, onAdd }) => {
	const isPastEvent = event?.end < now;
	return (
		<div>
			<div className='calendar-highlighted-card' style={{ background: event.color.background, border: `2px solid ${event.color?.border}`, opacity: isPastEvent ? 0.5 : 1, boxShadow: `0px 2px 6px ${event.color?.border}` }}>
				<div >
					<div >
						<div>
							<div style={{ fontSize: '18px', fontWeight: '600', paddingBottom: '8px' }}>
								{event?.title}
							</div>
						</div>
					</div>
					<div>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup id='bookingDate'>
									<label id='bookingDate' style={{ fontWeight: '500' }}>
										{dayjs(event.start).format('ddd D MMM YYYY')}
									</label>
								</FormGroup>
							</div>
							<div className='col-12'>
								<div className='col-12'>
									<div className='row g-1 align-items-baseline'>
										<label style={{ fontWeight: '500', paddingBottom: '8px' }}>
											{event?.availability.startTime}
										</label>
										<div className='col-12 d-flex align-items-center-vertically'>
											<label style={{ fontWeight: '500' }}>
												{event.availability.vacancies} {'Available'}
											</label>
											<LiaEdit style={{ marginLeft: '10px', width: '24px', height: '24px' }} rounded={1} icon='Edit' onClick={() => onEdit(event)} />
										</div>
										<div className='col-12 d-flex align-items-center-vertically' style={{ marginTop: '16px' }}>
											<label style={{ fontWeight: '500', paddingBottom: '8px' }}>
												{event?.availability?.bookings?.length} {'Bookings'}
											</label>
											<LiaPlusSquare style={{ marginLeft: '10px', width: '24px', height: '24px' }} rounded={1} icon='Edit' onClick={() => onAdd(event)} />

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const AgebandCounter = ({ code, count, setCount }) => {
	const increment = () => {
		setCount(code, count + 1);
	};

	const decrement = () => {
		if (count > 0) {
			setCount(code, count - 1);
		}
	};

	return (
		<div className="counter-container">
			<label className="counter-label">{code}</label>
			<div className="counter">
				<button className="counter-button" onClick={decrement}>-</button>
				<div className='counter-box'>
					<label className="counter-value">{count}</label>
				</div>
				<button className="counter-button" onClick={increment}>+</button>
			</div>
		</div>
	);
};

const Editavailability = ({ event, onClose }) => {
	const navigate = useNavigate();
	const availabilityId = `${event?.availabilityId}` ?? ''
	const [inputValue, setInputValue] = useState(`${event?.availability.vacancies}`);

	const [editAvailability, { data, loading, error }] = useMutation(EDIT_AVALIABILITY, {
	});

	function post({ variables }) {
		try {
			editAvailability({ variables });
		} catch (error) {
			if (authError(error)) {
				navigate('/login');
			}
		}
	}

	useEffect(() => {
		if (data) {
			setInputValue(data.editAvailability);
		}
	}, [data]);

	const increment = async () => {
		post({
			variables: {
				input: {
					increase: true,
					id: availabilityId,
					decrease: false,
					newValue: undefined
				}
			}
		});
	};

	const decrement = () => {
		post({
			variables: {
				input: {
					decrease: true,
					id: availabilityId,
					increase: false,
					newValue: undefined
				}
			}
		});
	}

	const handleSubmit = () => {
		post({
			variables: {
				input: {
					decrease: false,
					id: availabilityId,
					increase: false,
					newValue: Number(inputValue)
				}
			}
		});
	};

	if (error) {
		if (authError(error)) {
			navigate('/login');
		}
		return <p>Error : {error.message}</p>;
	}

	return (
		<div>
			<div>
				<div style={{ background: event.color.background, padding: '16px' }}>
					<div className='col-12'>
						<label style={{ fontSize: '14px' }} >
							{event?.title}
						</label>
					</div>
					<div className='col-12'>
						<label style={{ fontSize: '14px', fontWeight: 600 }} >
							{`${dayjs(new Date(+event?.availability.date)).format('ddd D MMM YYYY')} at ${event?.availability.startTime}`}
						</label>
					</div>
				</div>
				<div style={{ padding: '24px 24px 24px 16px' }}>
					{loading ? <div className='center-container'> <Spinner style={{ color: event.color.border }} /> </div> :
						<div>
							<div className="counter-container">
								<label style={{ width: 'auto' }} className="counter-label">{'Availabilities'}</label>
								<div className="counter">
									<button className="counter-button" onClick={decrement}>-</button>
									<form onSubmit={handleSubmit}>
										<input
											style={{ textAlign: 'center', width: '30px', marginLeft: '10px', marginRight: '10px' }}
											value={inputValue}
											onChange={(e) => setInputValue(e.target.value)}
										/>
									</form>
									<button className="counter-button" onClick={increment}>+</button>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

const ReserveBooking = ({ event, onClose }) => {
	const navigate = useNavigate();
	const experienceId = `${event?.experienceId}` ?? ''
	const experienceOptionId = `${event?.optionId}` ?? ''
	const availabilityId = `${event?.availability.id}` ?? ''
	const availability = event.availability
	const [errorMessage, setErrorMessage] = useState('');
	const [reserveBooking, { data, loading, error }] = useMutation(RESERVE_BOOKING, {
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			title: '',
			ageBands: event?.availability?.rates?.map((rate) => {
				return { code: rate.ageBand.code, count: 0, amount: rate.amount ?? 0, currency: rate.currency ?? 'GBP' }
			})
		}, onSubmit: (values) => { }
	});

	function updateAgeBand(code, count) {
		const updatedAgeBands = formik.values.ageBands?.map((ageBand) =>
			ageBand.code === code ? { ...ageBand, count: count } : ageBand
		);
		formik.setValues((prevValues) => {
			return {
				...prevValues,
				ageBands: updatedAgeBands,
			};
		});
	}

	function total() {
		if (!validate()) {
			return '-'
		}
		const ageBands = formik.values.ageBands ?? [];
		const currency = ageBands[0].currency;
		const prices = ageBands.map((ageBand) => {
			return ageBand.amount * ageBand.count;
		});
		const sum = prices.reduce((acc, price) => {
			return acc + price
		});

		if (sum) {
			return currency + ' ' + sum;
		}
		return '';
	}

	async function post() {
		try {
			await reserveBooking(
				{
					variables: {
						input: {
							experienceId: experienceId,
							experienceOptionId: experienceOptionId,
							availabilityId: availabilityId,
							contact: {
								firstName: formik.values.firstName,
								lastName: formik.values.lastName,
								email: formik.values.email,
								phoneNumber: formik.values.phoneNumber,
								title: formik.values.title
							},
							ageBands: formik.values.ageBands?.filter((ageBand) => ageBand.count > 0)
								.map((ageBand) => { return { code: ageBand.code, count: ageBand.count } })
						}
					}
				}
			);
		} catch (error) {
			if (authError(error)) {
				navigate('/login');
			}
			const graphQLErrors = error;
			if (graphQLErrors && graphQLErrors.graphQLErrors) {
				setErrorMessage(graphQLErrors.graphQLErrors[0]?.message);
			}
		}
	}

	function validate() {
		return formik.values.ageBands?.filter((ageBand) => ageBand.count > 0)?.length ?? 0 > 0
	}

	useEffect(() => {
		if (data?.reserveBooking) {
			onClose();
		} else if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
			setErrorMessage(error.graphQLErrors[0]?.message);
		}
	}, [data, error]);

	const handleSubmit = () => {
		setErrorMessage('');
		post();
	};

	if (loading) return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
			<Spinner />
		</div>);

	return (
		<div>
			<div>
				<div style={{ background: event.color?.background, padding: '16px' }}>
					<div className='col-12'>
						<label style={{ fontSize: '14px' }} >
							{event?.title}
						</label>
					</div>
					<div className='col-12'>
						<label style={{ fontSize: '14px', fontWeight: 600 }} >
							{`${dayjs(new Date(+availability.date)).format('ddd D MMM YYYY')} at ${availability.startTime}`}
						</label>
					</div>
				</div>
				<div style={{ padding: '16px' }}>

					<div className='row g-2'>
						<div style={{ paddingTop: '16px', paddingBottom: '8px' }}>
							<label style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '8px' }} >
								Number of participants
							</label>
							{availability.rates ? (
								<>
									{availability.rates.map((rate) => (
										<div className='col-12' key={rate.id}>
											<AgebandCounter code={rate.ageBand.code} count={formik.values.ageBands?.findLast((ageBand) => ageBand.code === rate.ageBand.code)?.count ?? 0} setCount={updateAgeBand} />
										</div>
									))}
								</>
							) : (
								<div>No rates available</div>
							)}



						</div >

						<div className='col-12'>
							<input
								className='form-input'
								placeholder="Email"
								name="email"
								onChange={formik.handleChange}
								value={formik.values.email}
							/>
						</div>

						<div className='col-12'>
							<input
								className='form-input'
								placeholder="Contact name"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
							/>
						</div>
						<div className='col-12'>

							<input
								type="tel"
								className='form-input'
								placeholder="Phone number"
								name="phoneNumber"
								pattern="\d{3}[-]\d{3}[-]\d{4}"
								value={formik.values.phoneNumber}
								onChange={formik.handleChange}
							/>
						</div>
						{errorMessage && errorMessage.length > 0 &&
							<div className='col-12'>
								<label style={{ color: 'red', fontWeight: 300, fontSize: '13px' }} >
									{errorMessage}
								</label>
							</div>
						}
					</div >

					<div className='bg-l25-info'>
						<div icon='DateRange' iconColor='info'>
							<label style={{ fontWeight: 600, fontSize: '16px', paddingTop: '16px' }} >
								{'Total: ' + total()}
							</label>

							{validate() && formik.values.ageBands?.filter((ageBand) => { return ageBand.count > 0 }) ? (
								<>
									{formik.values.ageBands
										.filter((ageBand) => { return ageBand.count > 0 })
										.map((ageBand) => (
											<div className='col-12' key={ageBand.code}>
												<label style={{ fontSize: '14px', paddingTop: '4px' }}>
													{`${ageBand.currency} ${ageBand.amount} x ${ageBand.count}`}
												</label>
											</div>
										))}
								</>
							) : (
								<div>-</div>
							)}

						</div>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '32px', width: '100%' }}>
				<button className='book-button' isDisable={!validate()} onClick={() => {

					handleSubmit()
				}
				}>
					Book
				</button>
			</div>
		</div>
	);
};

const CancelBooking = ({ event, date, booking, onClose, onCancelled }) => {
	const navigate = useNavigate();
	const bookingId = `${booking.id}` ?? ''
	console.log(JSON.stringify(event))
	const [errorMessage, setErrorMessage] = useState('');
	const [cancelBooking, { data, loading, error }] = useMutation(CANCEL_BOOKING, {
	});

	async function post({ variables }) {
		try {
			await cancelBooking({ variables });
		} catch (error) {
			if (authError(error)) {
				navigate('/login');
			}
		}
	}

	const handleSubmit = () => {
		post({ variables: { bookingId: booking.id } });
	};

	useEffect(() => {
		console.log(`data: ${JSON.stringify(data)}`)
		if (data?.cancelBooking?.status == 'CANCELLED') {
			onClose();
		} else if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
			setErrorMessage(error.graphQLErrors[0].message);
		}
	}, [data, error]);

	if (loading) return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
			<Spinner />
		</div>);

	return (
		<div>
			<div>
				<div style={{ background: event.color?.background, padding: '16px' }}>

					<div className='col-12'>
						<label style={{ fontSize: '16px', fontWeight: 600 }}>
							Please confirm booking cancellation
						</label>
					</div>
				</div>
				<div style={{ padding: '16px' }}>

					<div className='row g-2'>
						<div style={{ paddingTop: '16px', paddingBottom: '8px' }}>
							<label style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '8px' }} >
								{`Reference: ${booking.reference}`}
							</label>
							<label style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '8px' }} >
								{`Date: ${dayjs(date).format('ddd D MMM YYYY [at] h:mm A')}`}
							</label>
							<label style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '8px' }} >
								{`Participants: ${booking.participants}`}
							</label>
							<label style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '8px' }} >
								{`Total: ${booking.payment.total.currency} ${booking.payment.total.amount}`}
							</label>
						</div>
					</div >

					{errorMessage && errorMessage.length > 0 &&
						<div className='col-12'>
							<label style={{ color: 'red', fontWeight: 300, fontSize: '13px' }} >
								{errorMessage}
							</label>
						</div>
					}
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '32px', width: '100%' }}>
				<button className='cancel-button' onClick={() => { handleSubmit() }}>
					Cancel booking
				</button>
			</div>

			<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '32px', width: '100%' }}>
				<button className='booking-close-button-tertiary' onClick={() => { onClose() }}>
					Close
				</button>
			</div>
		</div>
	);
};

const AvailabilityBookings = ({ event, date, bookings, update, isFluid = false }) => {
	console.log(`booking event: ${JSON.stringify(event)}`)
	const [isCancelBookingOpen, setIsCancelBookingOpen] = useState(false);
	const [cancelBooking, setCancelBooking] = useState();

	const closeModal = () => {
		setIsCancelBookingOpen(false);
		update();
	};

	const openModal = (booking) => {
		setIsCancelBookingOpen(true);
		setCancelBooking(booking)
	};

	const onCancelModal = () => {
		setIsCancelBookingOpen(false);
	};

	return (
		<>
			<div stretch={isFluid}>
				<div borderSize={1}>
					<div icon='Alarm' iconColor='info'>
						<div tag='div' className='h5'>
							Bookings on {dayjs(date).format('ddd D MMM YYYY [at] h:mm A')}
						</div>
					</div>
				</div>
				<div className='table-responsive'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>Reference</th>
								<th>Contact</th>
								<th>Participants</th>
								<th>Status</th>
								<th>Total</th>
								<th> </th>
							</tr>
						</thead>
						<tbody>
							{dataPagination(bookings, 1, 5).map((item) => (
								<tr key={item.id}>
									<td>
										<div className='d-flex align-items-center'>
											<div className='booking-status-wrapper'>
												<div className={ `booking-status-${item.status}-circle` } />
												<label className='booking-label' style={{ marginLeft: '8px' }}> {item.reference} </label>
											</div>
										</div>
									</td>
									<td>
										<div>
											<label className='booking-label'>{item.contact?.email ?? 'n/a'}</label>
										</div>
									</td>
									<td>
										<div>
											<label className='booking-label'>{item.participants}</label>
										</div>
									</td>
									<td>
										<div className='d-flex'>
											<label className='booking-label'>
												{`${item.status}`}
											</label>
										</div>
									</td>
									<td>
										<label className='booking-label'>
											{item.payment && `${item.payment.total.currency} ${item.payment.total.amount}`}
										</label>
									</td>

									<td>
										<div className='d-flex'>
											{item.status === 'CONFIRMED' &&
											<button className='booking-button-tertiary' onClick={() => { openModal(item) }}>
												{'Cancel'}
											</button>
}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{cancelBooking &&
				<Modal
					className='card'
					isOpen={isCancelBookingOpen}
					onRequestClose={closeModal}
					contentLabel="My Modal"
					style={{
						content: {
							position: 'relative',
							width: '280px',
							margin: '16px',
						},
						overlay: {
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							zIndex: 1000,
						},
					}}
				>
					{cancelBooking && <CancelBooking event={event?.selectedEvent} date={date} booking={cancelBooking} onClose={closeModal} onCancelled={onCancelModal} />}
				</Modal>
			}
		</>
	);
};

function bookingDate(booking) {
	const [hours, minutes] = booking.dateTime.startTime.split(':').map(Number);
	return dayjs(new Date(+booking.dateTime.date)).startOf('day').hour(hours).minute(minutes).format('h:mm');
}


const CalendarPage = () => {
	Modal.setAppElement('#root');
	const navigate = useNavigate();
	const location = useLocation();
	const experienceId = location.state?.experienceId;

	const [isEditAvailabilityOpen, setIsEditAvailabilityOpen] = useState(false);
	const [isReserveBookingOpen, setIsReserveBookingOpen] = useState(false);

	const [availability, setAvailability] = useState();
	const [minTime, setMinTime] = useState(now);
	const [maxTime, setMaxTime] = useState(now);
	const [isEventSelected, setIsEventSelected] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState();
	const [eventAdding, setEventAdding] = useState(false);

	const handleSelect = (eventItem) => {
		setIsEventSelected(true);
		setSelectedEvent(eventItem);
	};

	const handleSlotSelect = (slot) => {
		setIsEventSelected(false);
		setSelectedEvent(undefined);
	};

	const handleEditClick = (item) => {
		if (item?.end < now) {
			return;
		}
		setAvailability(item);
		setIsReserveBookingOpen(false);
		setIsEditAvailabilityOpen(true);
	};

	const handleAddClick = (item) => {
		if (item?.end < now) {
			return;
		}
		setAvailability(item);
		setIsReserveBookingOpen(true);
		setIsEditAvailabilityOpen(false);
	};

	const closeModal = () => {
		setIsReserveBookingOpen(false);
		setIsEditAvailabilityOpen(false);
		refetch();
	};

	const elist = []

	const [events, setEvents] = useState(elist);

	const [bookingEventItem, setBookingEventItem] = useState();
	const [date, setDate] = useState(new Date());
	const [toggleInfoEventCanvas, setToggleInfoEventCanvas] = useState(false);
	const setInfoEvent = () => setToggleInfoEventCanvas(!toggleInfoEventCanvas);

	useEffect(() => {
		if (eventAdding) {
			setInfoEvent();
		}
	}, [eventAdding]);

	const formik = useFormik({
		initialValues: {
			eventName: '',
			eventStart: '',
			eventEnd: '',
			eventEmployee: '',
			eventAllDay: false,
			eventContact: {
				name: '',
				email: '',
				phone: ''
			}

		},
		onSubmit: (values) => {
			setEventAdding(false);
			setBookingEventItem(undefined);
			formik.setValues({
				eventName: '',
				eventStart: '',
				eventEnd: '',
				eventEmployee: '',
				eventAllDay: false,
				eventContact: {
					name: '',
					email: '',
					phone: ''
				}

			});
		},
		validate: (values) => {
			const errors = {};
			return errors;
		},
	});

	const { loading, error, data, refetch } = useQuery(GET_DETAILED_AVALIABILITIES, {
		variables: { input: { experienceId: experienceId } },
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (data) {
			const events = mapAvailabilitiesToEvents(data)
			const times = findMinMaxDates(events);
			setMinTime(times.minDate);
			setMaxTime(times.maxDate);
			setEvents(events);

			if(selectedEvent) {
				const updatedEvents = events.filter((event) => event.id === selectedEvent.id);
				if (updatedEvents.length > 0) {
					setSelectedEvent(updatedEvents[0]);
				}
			}
		}
	}, [data]);

	useEffect(() => {
		if (bookingEventItem)
			formik.setValues({
				...formik.values,
				eventId: bookingEventItem.id || null,
				eventName: bookingEventItem.name || '',
				eventStart: dayjs(bookingEventItem.start).format(),
				eventEnd: dayjs(bookingEventItem.end).format(),
				eventEmployee: bookingEventItem?.user?.username || '',
			});
		return () => { };
	}, [bookingEventItem]);

	if (loading) return (<Preloader />);

	if (error) {
		if (authError(error)) {
			navigate('/login');
		}
		return (
			<Layout>
				<div style={{
					padding: '50px',
					marginBottom: '10px',
					marginTop: '600px',
				}}>
					<p>Error : {error.message}</p>;

				</div>
			</Layout>
		);
	}

	const dateToday = dayjs();
	const todayEvent = events.filter((event) => {
		return dayjs(event.start).isSame(dateToday, 'day');
	});

	const customFormats = {
		dayFormat: 'ddd DD MMM',
	};

	const eventStyleGetter = (event, start, end, isSelected) => {
		let newStyle = {
			color: 'black',
			backgroundColor: 'transparent',
			border: 'none',
			display: 'block',
		};

		return {
			className: "",
			style: newStyle
		};
	};

	const WeekEvent = (data) => {

		const { event } = data;
		const isPastEvent = event?.end < now;
		const isSelected = event.id === selectedEvent?.id;

		return (
			<div className='event-item' style={{ background: event.color.background, border: `2px solid ${event.color?.border}`, opacity: isPastEvent ? 0.5 : 1, boxShadow: isSelected ? `0px 2px 6px ${event.color?.border}` : '' }}>
				<div className='event-title'>
					{event?.title}
				</div>
				<div style={{ padding: '2px' }}>
					<div className='row g-1 align-items-baseline'>
						<div className='event-time'>
							{event?.availability.startTime}
						</div>
						<small className='event-time' style={{ color: event.color.border, textOverflow: 'ellipsis' }}>
							{event?.availability.vacancies} {'Available'}
						</small>
						<small className='event-time' style={{ color: event.color.border, textOverflow: 'ellipsis' }}>
							{event?.availability?.bookings?.length} {'Bookings'}
						</small>
					</div>
				</div>
			</div>
		);
	};
	
	return (
		<Layout>
			<div className='calendar-page-content'>
				<div className='calendar-title'>
					{`You have ${todayEvent.reduce((acc, event) => acc + event.availability.bookings.length, 0)} bookings today`}
				</div>
				<div className='calendar-title'>
					<CalendarTodayButton
						unitType={'WEEK'}
						date={date}
						setDate={setDate}
					/>
				</div>
				<div className='grid-gap' style={{
					display: 'flex',
					flexWrap: 'wrap',
					border: 'none'
				}}>
					<Calendar
						min={minTime}
						max={maxTime}
						formats={customFormats}
						selectable={false}
						toolbar={false}
						localizer={localizer}
						events={events}
						defaultView={Views.WEEK}
						views={[Views.WEEK]}
						date={date}
						onNavigate={(_date) => setDate(_date)}
						scrollToTime={new Date(1970, 1, 1, 6)}
						defaultDate={new Date()}
						dayLayoutAlgorithm={'overlap'}
						onSelectEvent={handleSelect}
						onSelectSlot={handleSlotSelect}
						components={{
							timeGutterHeader: EmptyGutter,
							timeGutter: EmptyGutter,
							event: WeekEvent,
							week: {
								event: WeekEvent,
							},
						}}
						eventPropGetter={eventStyleGetter}
						style={{ height: 500, minWidth: '70%', marginBottom: '40px' }}
					/>
					<div style={{ padding: '10px' }} />
					{isEventSelected && selectedEvent && (
						<Highlightevent event={selectedEvent} onEdit={handleEditClick} onAdd={handleAddClick} />
					)}

				</div>


				{availability &&
					<Modal
						className='card'
						isOpen={isReserveBookingOpen}
						onRequestClose={closeModal}
						contentLabel="My Modal"
						style={{
							content: {
								position: 'relative',
								width: '380px',
								margin: '16px',
							},
							overlay: {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								zIndex: 1000,
							},
						}}
					>
						{availability && <ReserveBooking event={availability} onClose={closeModal} />}
					</Modal>
				}

				{availability &&
					<Modal
						className='card'
						isOpen={isEditAvailabilityOpen}
						onRequestClose={closeModal}
						contentLabel="My Modal"
						style={{
							content: {
								position: 'relative',
								width: '280px',
								margin: '16px',
							},
							overlay: {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								zIndex: 1000,
							},
						}}
					>
						{availability && <Editavailability event={availability} onClose={closeModal} />}
					</Modal>
				}

				{isEventSelected && selectedEvent.availability?.bookings.length > 0 && (
					<div>
						<AvailabilityBookings event={{ selectedEvent }} date={(selectedEvent)?.start} bookings={(selectedEvent)?.availability?.bookings} update={() => refetch()} />
					</div>
				)}
			</div>
		</Layout>
	);

	function findMinMaxDates(events) {
		if (!events || events.length === 0) {
			return null;
		}
		let minHour;
		let maxHour;
		events.forEach(event => {
			const startHour = dayjs(event.start).hour()
			const endHour = dayjs(event.end).hour()

			if (minHour == null || startHour < minHour) {
				minHour = startHour
			}
			if (maxHour == null || endHour > maxHour) {
				maxHour = endHour;
			}
		});

		const minDate = dayjs(new Date()).startOf('day').hour(minHour).toDate();
		const maxDate = dayjs(new Date()).startOf('day').hour(maxHour).toDate();
		return { minDate, maxDate };
	}
};

const dataPagination = (data, currentPage, perPage) =>
	data.filter(
		(i, index) => index + 1 > (currentPage - 1) * perPage && index + 1 <= currentPage * perPage,
	);

export default CalendarPage;
