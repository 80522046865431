import { format } from 'date-fns';
import dayjs from 'dayjs';
import SHA256 from 'crypto-js/sha256';


export function mapAvailabilitiesToEvents(response) {
  if (response == null || response.experienceOptionsWithDetailedAvailabilities == null || response.experienceOptionsWithDetailedAvailabilities.options == null) {
    return [];
  }
  
  const colors = [
    { 'border': '#D2A981', 'background': '#FBEAD8' },
    { 'border': '#4E986A', 'background': '#D9FCE8' },
    { 'border': '#A35FAF', 'background': '#F7D9FE' },
    { 'border': '#B669A0', 'background': '#FDD8F2' }
  ];

  const uniqueStartTimes = new Set(response.experienceOptionsWithDetailedAvailabilities.options.map((option) => {
    return option.availabilities.map((availability) => {
      return  availability.startTime.replace(':', '');
    });
  }).flat());

  const startTimes = Array.from(uniqueStartTimes).sort();
  const startHourDriver = 9;

  return response.experienceOptionsWithDetailedAvailabilities.options.map((option) => {
    return option.availabilities.map((availability) => {
      const optionIndex = response.experienceOptionsWithDetailedAvailabilities.options.findIndex((o) => o.id === option.id) ?? 0;
      const startTimeIndex = startTimes.findIndex((o) => { return o === availability.startTime.replace(':', '') }) ?? 0;
      const startHour = startHourDriver + (optionIndex * 1) + (startTimeIndex * 1 );
      const startTime = dayjs(new Date(+availability.date)).startOf('day').hour(startHour).minute(0).toDate();
      const endTime = dayjs(new Date(+availability.date)).startOf('day').hour(startHour).minute(120).toDate();

      return {
        id: SHA256(`${option.id}-${availability.id}`).toString(),
        experienceId: response.experienceOptionsWithDetailedAvailabilities.experienceId,
        optionId: option.id,
        availabilityId: availability.id,
        title: option.title,
        start: startTime,
        end: endTime,
        color: colors[optionIndex % colors.length],
        availability: { ...availability },
      }
    })
  }).flat();
}

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    const groupKey = currentValue[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentValue);
    return result;
  }, {});
};