import { gql } from '@apollo/client';

export const GET_EXPERIENCES = gql`
query Experiences {
  experiences {
    edges {
      cursor
      node {
        shortDescription
        title
        id
        defaultImage {
          sizes {
            url
          }
        }
      }
    }
  }
}
`;

export const GET_DETAILED_AVALIABILITIES = gql`
query ExperienceOptionsWithDetailedAvailabilities($input: ExperienceOptionsWithDetailedAvailabilityInput) {
  experienceOptionsWithDetailedAvailabilities(input: $input) {
    experienceId
    options {
      availabilities {
        date
        id
        rates {
          ageBand {
            code
          }
          amount
          currency
        }
        startTime
        vacancies
        bookings {
          id
          dateTime {
            date
            startTime
          }
          payment {
            date
            key
            total {
              amount
              currency
            }
          }
          participants
          reference
          refundStatus
          status
          contact {
            firstName
            lastName
            email
          } 
        }
      }
      id
      title
    }
  }
}
`;