import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Preloader from "../components/Preloader";

const logo = require("../assets/images/logo.png");


export const updateUser = (userData) => {
    return {
        type: 'SUBSCRIBE_USER',
        payload: userData
    };
};


const Logout = () => {

   const dispatch = useDispatch();

    const navigate = useNavigate();

    dispatch(updateUser(null));

    navigate('/login');

    return (<Preloader />);
};

export default Logout;
