import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { doSignInWithEmailAndPassword } from '../firebase/auth'
import { useDispatch } from 'react-redux';
import { store } from '../redux/store'
import { getIsUserLoggedIn } from '../redux/selectors/authSelectors';
import Preloader from "../components/Preloader";

const logo = require("../assets/images/logo.png");


export const updateUser = (userData) => {
	return {
		type: 'SUBSCRIBE_USER',
		payload: userData
	};
};


const Login = () => {

	const state = store.getState();

	const dispatch = useDispatch();

	const [disabled, setDisabled] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');

	const navigate = useNavigate();

	const isValidEmail = () => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(formik.values.loginUsername);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
			loginFailure: '',
		},
		validate: (values) => {
			setErrorMessage('');
			setDisabled(!isValidEmail() || values.loginPassword.length < 5);
		},
		validateOnChange: true,
		onSubmit: (values) => {
			setErrorMessage('');
			handleSubmit(values);
		},
	});

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (values) => {
		setErrorMessage('');
		if (!isLoading) {
			setIsLoading(true);
			try {
				const result = await doSignInWithEmailAndPassword(values.loginUsername, values.loginPassword)
				setIsLoading(false);
				dispatch(updateUser(result.user))
				navigate('/')
			} catch (error) {
				console.log('Sign in with error', JSON.stringify(error));
				setIsLoading(false);
				if (error.code === 'auth/invalid-credential') {
					setErrorMessage('Invalid credentials. Please check your email and password and try again.');
				} else {
					setErrorMessage('There was an error signing in. Please try again.');
				}
			}
		}
	};

	if (getIsUserLoggedIn(state)) {
		navigate('/');
		return (<Preloader />);
	}

	return (

		<div className="center-container">
			<div style={{
				padding: '50px', // Padding around the input field
				marginBottom: '10px' // Spacing below the input field
			}}>
				<div className="card">
					<div className="card-header">
						<img src={logo} alt="Mobile Banner" style={{ height: "40px" }} />

					</div>
					<div className="form-container">
						<form onSubmit={handleSubmit}>

							<div className="center-container">

								<input
									autoComplete='username'
									type="text"
									placeholder="Email"
									name="loginUsername"
									value={formik.values.loginUsername}
									onChange={formik.handleChange}


									style={{
										border: true ? '1px solid #ccc' : '1px solid red', // Border color changes based on validity
										padding: '6px', // Padding around the input field
										borderRadius: '5px', // Border radius
										width: '70%', // Width of the input field
										marginBottom: '10px',
										marginTop: '50px',
										fontSize: '14px' // Spacing below the input field
									}}

								/>

							</div>
							<div className="center-container">
								<input
									type="password"
									placeholder="Passw0rd"
									name="loginPassword"
									onChange={formik.handleChange}
									style={{
										border: true ? '1px solid #ccc' : '1px solid red', // Border color changes based on validity
										padding: '6px', // Padding around the input field
										borderRadius: '5px', // Border radius
										width: '70%', // Width of the input field
										marginBottom: '10px',
										marginTop: '10px',
										fontSize: '14px' // Spacing below the input field
									}}
								/>
							</div>
							<div className="center-container">
								<div style={{
									minHeight: '30px',
									width: '70%',// Height of the button
								}}
								>
									{errorMessage && <span className="error">{errorMessage}</span>}
								</div>
							</div>
							<div className="submit-wrap">
								<button type="submit" className="login-button" disabled={disabled} onClick={formik.handleSubmit}>
									{isLoading ? 'Please wait...' : 'Login'}
								</button>
							</div>
						</form>

					</div>
				</div>
				<FooterLinks />
			</div>
		</div>

	);
};

const FooterLinks = () => {
	return (
		<p>
			<Link style={{float: 'right', justifyContent: 'end' }} to="/terms-and-conditions" className='link' >Terms & Conditions</Link>
		</p>
	);
};

export default Login;
