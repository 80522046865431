import  { ApolloClient, ApolloLink, InMemoryCache, HttpLink, concat } from "@apollo/client";
import { store } from '../redux/store'

const apiUrl = process.env.REACT_APP_API_URL;
const env = process.env.REACT_APP_DATA_ENV;
const httpLink = new HttpLink({ uri: `${apiUrl}/${env}/supplier/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
const state = store.getState();
const token = state?.accessToken;

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});