import { gql } from '@apollo/client';

export const EDIT_AVALIABILITY = gql`
mutation EditAvailability($input: EditAvailabilityInput) {
    editAvailability(input: $input)
  }
`;

export const RESERVE_BOOKING = gql`
mutation ReserveBooking($input: BookingInput) {
  reserveBooking(input: $input)
}
`;
export const CANCEL_BOOKING = gql`
mutation CancelBooking($bookingId: ID!) {
  cancelBooking(bookingId: $bookingId) {
    id
    status
    refundStatus
    reference
  }
}
`;