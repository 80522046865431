import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { ApolloProvider } from '@apollo/client';
import { apolloClient as client } from "./graphql/ApolloClient";
// Redux Import
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { fetchAndActivate } from "firebase/remote-config";
import { configs as remoteConfigs } from "./firebase/firebase.js";
import AppRouter from "./routers/AppRouter.js";

function App() {
  remoteConfigs.settings.minimumFetchIntervalMillis = 1;
  const [isConfigFetched, setIsConfigFetched] = useState(false);
  useEffect(() => {

    fetchAndActivate(remoteConfigs)
      .then(() => {
        setIsConfigFetched(true);
      })
      .catch((err) => {
        setIsConfigFetched(true);
      });
  }, []);

  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <AppRouter isConfigFetched={isConfigFetched} />
        </ApolloProvider>
      </PersistGate>
    </Provider>

  );
}

export default App;
