export const firebaseConfig = {
    dev: {
        apiKey: "AIzaSyAm2ISlCuW_QjXc6Gyn0mUmu6KN2Iw3p6M",
        authDomain: "supplier-the-booking-platform.firebaseapp.com",
        projectId: "supplier-the-booking-platform",
        storageBucket: "supplier-the-booking-platform.appspot.com",
        messagingSenderId: "361978822167",
        appId: "1:361978822167:web:0338c999c02486f25fa652",
        measurementId: "G-8MDY0VDB0Z"
    },
    prod: {
        apiKey: "AIzaSyBMyfbLo5kLsUnxXGvp_6i5PqJ-eib9D7g",
        authDomain: "prodsupplierthebookingplatform.firebaseapp.com",
        projectId: "prodsupplierthebookingplatform",
        storageBucket: "prodsupplierthebookingplatform.appspot.com",
        messagingSenderId: "557191878820",
        appId: "1:557191878820:web:8f15464cab8504fb72ae31",
        measurementId: "G-TVBS44BMB4"
    }
};