const initialState = {
  userName: null,
  accessToken: null,
  userloggedin: false,
  loading: true
};


export default (state = initialState, action) => {
  switch (action.type) {
    case "SUBSCRIBE_USER":
      return {
        ...state,
        userName: action.payload?.userName,
        accessToken: action.payload?.stsTokenManager?.accessToken,
        userloggedin: !!action.payload?.stsTokenManager?.accessToken,
        loading: false
      };
    case "UNSUBSCRIBE_USER":

      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};




