import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Login from "../views/LoginPage";
import Home from "../views/Home";
import Preloader from "../components/Preloader";
import Logout from "../views/Logout";
import CalendarPage from "../views/CalendarPage";
import TermsAndConditions from "../views/TermsAndConditions/TermsAndConditions";


const AppRouter = (isConfigFetched) => (

  <Router>
    {!isConfigFetched && <Preloader />}

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="*" element={<Home />} />
    </Routes>
  </Router>
);

export default AppRouter;

