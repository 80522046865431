import React from "react";
import Layout from "../layout";
import SiteBanner from "../sections/SiteBanner";
import About from "../sections/About";
import ErrorBoundary from "../error/ErrorBoundary";
import { GET_EXPERIENCES } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import Download from "../sections/Download";
import Contact from "../sections/Contact";
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { store } from '../redux/store'
import { getIsUserLoggedIn } from '../redux/selectors/authSelectors';
import Preloader from "../components/Preloader";
import { authError } from "../utils/ErrorMapper";

export const updateUser = (userData) => {
  return {
    type: 'SUBSCRIBE_USER',
    payload: userData
  };
};

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = store.getState();

  const { loading, error, data } = useQuery(GET_EXPERIENCES, {
    fetchPolicy: 'no-cache',
  });

  if (!getIsUserLoggedIn(state)) {
    navigate('/login');
    return (<Preloader />);
  }

  if (loading) return (
    <Preloader />
  );

  if (error) {
    console.log('error', JSON.stringify(error));
    if (authError(error)) {
      dispatch(updateUser(null));
      navigate('/login');
    }
    return (
			<Layout>
				<div style={{
					padding: '50px',
					marginBottom: '10px',
					marginTop: '60px',
				}}>
					<p>Error : {error.message}</p>;

				</div>
			</Layout>
		);
}

if (data?.experiences && data.experiences.edges.length === 1) { 
  navigate('/calendar', { state: { experienceId: data.experiences.edges[0].node.id } });
  return (<Preloader />);
}

  return (
    <Layout>
      <div style={{
        padding: '50px',
        marginBottom: '10px',
        marginTop: '60px',
      }}>
        <div key={'home-container'} className="home-container">
          {data?.experiences && data.experiences.edges.map((edge) => (
            <div className="home-card"  onClick={() => {
              navigate('/calendar', { state: { experienceId: edge.node.id } });
          }}>
              <div>
                <img
                  className="home-item-image"
                  src={edge.node.defaultImage.sizes[0].url}
                />
              </div>
              <div>
                <label className="home-item-title">{edge.node.title}</label>
              </div>
            </div>
          ))}
          <div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
